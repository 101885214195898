import * as Sentry from '@sentry/gatsby';

Sentry.init({
    dsn: "https://71f95973ee212ffad9c3317851517374@o4505074063441920.ingest.us.sentry.io/4507090402934784",
    integrations: [
        Sentry.replayIntegration(), 
        Sentry.browserTracingIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    tracePropagationTargets: ["localhost", /.*voetbalreizen.*api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    environment: process.env.WEBSITE + "-" + process.env.GATSBY_ACTIVE_ENV,
});
